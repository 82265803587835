// register the plugin on vue
import ABI from '@/assets/abi/tbtConsump.js'
import store from '../store'
const Contract = require('web3-eth-contract');
Contract.setProvider(store.state.rpcUrl);

export default class TBTConsump {
  constructor() {
    this.contract = new Contract(ABI, store.state.TBTConsumpAddress);
    // this.web3 = new Web3(new Web3.providers.HttpProvider(store.state.rpcURL));
    console.log('this.contract', this.contract)
  }

  async deposit(amount){
    amount = amount * (10 ** 18)
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.deposit(amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async adminWithdrawTbt(amount){
    amount = amount * (10 ** 18)
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.admin_withdrawal(store.state.TBTAddress, amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    // let web3
    // if (value){
    //   web3 = await new Web3(new Web3.providers.HttpProvider(rpcURL));
    // }
    const transactionParameters = {
      to: store.state.TBTConsumpAddress,
      // value: value ? web3.utils.toHex(value) : 0,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}